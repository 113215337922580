import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2,
} from "@mui/material";
import i18next from "i18next";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Term from "../../@types/services/Term";
import TermDocument from "../../@types/services/TermDocument";
import LoadingCentral from "../../components/molecules/LoadingCentral";
import TermDocumentForm from "../../components/molecules/TermDocumentForm";
import TermForm from "../../components/molecules/TermForm";
import { fetchTermDocument } from "../../services/termDocuments";
import { fetchTerm } from "../../services/terms";

const EditTerm: FC = () => {
  const { termId } = useParams();
  const [term, setTerm] = useState<Term>();
  const [termDocument, setTermDocument] = useState<TermDocument>();
  const navigate = useNavigate();

  const handleFetchTerm = async (id: string) => {
    fetchTerm(id).then((data) => {
      setTerm(data);
      fetchTermDocument(data.active_term_document.id).then((response) => {
        setTermDocument(response);
      });
    });
  };

  useEffect(() => {
    if (termId) {
      handleFetchTerm(termId);
    }
  }, []);

  if (!term || !termDocument) {
    return <LoadingCentral />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: "16px",
        width: "100%",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate(-1)}
        sx={{ alignSelf: "flex-start", marginBottom: 2 }}
      >
        {i18next.t("back")}
      </Button>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          marginBottom: 2,
        }}
      >
        <CardHeader title={i18next.t("terms.updateTerm")} />
        <CardContent>
          <Grid2 container spacing={2}>
            <Grid2 size={12}>
              <TermForm term={term} setTerm={setTerm} />
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>

      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "16px",
        }}
      >
        <CardHeader title={i18next.t("terms.updateDocument")} />
        <CardContent>
          <Grid2 container spacing={2}>
            <Grid2 size={12}>
              <TermDocumentForm
                termDocument={termDocument}
                setTermDocument={setTermDocument}
              />
            </Grid2>
            <Grid2 size={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <iframe
                  src={termDocument.attachment.url_inline}
                  width="100%"
                  height="800px"
                  style={{ marginTop: "16px" }}
                  title={termDocument.attachment.filename}
                />
              </Box>
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>
    </Box>
  );
};

export default EditTerm;
