import { CircularProgress } from "@mui/material";
import { FC, ReactElement, useEffect } from "react";

const Edit: FC = (): ReactElement => {
  useEffect(() => {
    location.reload();
  });

  return <CircularProgress size="lg" />;
};

export default Edit;
