import axios, { AxiosResponse } from "axios";
import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { InfoModalType } from "../@types/services";
import InfoModalContext from "../contexts/InfoModal/InfoModalContext";
import { useAuth } from "./AppProvider";

const InfoModalProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const { user } = useAuth();
  const [infoModal, setInfoModal] = useState<InfoModalType>();

  const fetchInfoModal = useCallback(async () => {
    const responseData: InfoModalType = await axios
      .get("/info_modal")
      .then(({ data }: AxiosResponse<any>) => {
        return {
          info_modal_enabled: data.info_modal_enabled,
          show_modal: data.show_modal,
          button: data.button,
          title: data.title,
          content: data.content,
        };
      });

    setInfoModal(() => responseData);
  }, []);

  const clearInfoModal = useCallback(() => {
    setInfoModal(() => undefined);
  }, []);

  useEffect(() => {
    if (user) {
      if (!infoModal || infoModal.show_modal) {
        fetchInfoModal();
      }
    } else {
      clearInfoModal();
    }
  }, [user, fetchInfoModal]);

  const providerValues = useMemo(() => {
    return {
      infoModal,
      fetchInfoModal,
      clearInfoModal,
    };
  }, [infoModal, fetchInfoModal, clearInfoModal]);

  return (
    <InfoModalContext.Provider value={providerValues}>
      {children}
    </InfoModalContext.Provider>
  );
};

export default InfoModalProvider;
