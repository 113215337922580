import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid2,
  Input,
  InputLabel,
  Paper,
  TextField,
} from "@mui/material";
import i18next from "i18next";
import { FC, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/atoms/PageTitle";
import { createTerm, NewTermForm } from "../../services/terms";

const NewTerm: FC = () => {
  const { handleSubmit, control } = useForm<NewTermForm>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFormSubmit: SubmitHandler<NewTermForm> = (data) => {
    setLoading(() => true);
    createTerm(data)
      .then(() => {
        navigate("/vsupplierregistration/terms");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(() => false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: "16px",
        height: "100%",
        width: "100%",
      }}
    >
      <PageTitle title={i18next.t("terms.newTerm")} />
      <Paper
        sx={{
          marginTop: 2,
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "24px",
        }}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Grid2 container spacing={2}>
            <Grid2 size={12}>
              <Controller
                name="name_pt_br"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={i18next.t("termForm.label.namePtBr")}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <Controller
                name="name_en"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={i18next.t("termForm.label.nameEn")}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <Controller
                name="mandatory"
                control={control}
                defaultValue={true}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={i18next.t("termForm.label.required")}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <Controller
                name="active"
                control={control}
                defaultValue={true}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={i18next.t("termForm.label.active")}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <Controller
                name="attachment"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <>
                    <InputLabel htmlFor="attachment">
                      {i18next.t("file")}
                    </InputLabel>
                    <FormControl fullWidth>
                      <Input
                        {...field}
                        type="file"
                        inputProps={{ accept: ".pdf,.doc,.docx" }}
                        id="attachment"
                        onChange={(e) =>
                          onChange((e.target as HTMLInputElement).files?.[0])
                        }
                      />
                    </FormControl>
                  </>
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <LoadingButton
                type="submit"
                style={{
                  marginTop: "8px",
                  justifyContent: "center",
                }}
                color="success"
                variant="contained"
                loading={loading}
                loadingPosition="center"
                fullWidth
              >
                {i18next.t("terms.button.create")}
              </LoadingButton>
            </Grid2>
          </Grid2>
        </form>
      </Paper>
    </Box>
  );
};

export default NewTerm;
