import AcceptanceTerm from "../@types/services/AcceptanceTerm";
import api from "./api";

const API_BASE_URL = "/vsupplierregistration/api/v2/acceptance_terms";

interface AcceptanceTermForm {
  accepted: boolean;
  term_document_id: string;
}

interface FetchAcceptanceTermsParams {
  page?: number;
  itemsPerPage?: number;
  order?: "asc" | "desc";
  filterTermDocument?: string;
}

interface AcceptanceTermsResponse {
  total_items: number;
  results: AcceptanceTerm[] | null;
}

export const fetchAcceptanceTerms = async ({
  page = 1,
  itemsPerPage = 25,
  order = "asc",
  filterTermDocument,
}: FetchAcceptanceTermsParams): Promise<AcceptanceTermsResponse> => {
  const response = await api.get<AcceptanceTermsResponse>("", {
    baseURL: API_BASE_URL,
    params: {
      page,
      items_per_page: itemsPerPage,
      order,
      term_document: filterTermDocument,
    },
  });

  return response.data;
};

export const fetchAcceptanceTerm = async (
  id: string,
): Promise<AcceptanceTerm> => {
  const response = await api.get<AcceptanceTerm>(`/${id}`, {
    baseURL: API_BASE_URL,
  });

  return response.data;
};

export const createAcceptanceTerm = async (
  data: AcceptanceTermForm,
): Promise<AcceptanceTerm> => {
  const response = await api.post<AcceptanceTerm>("", data, {
    baseURL: API_BASE_URL,
  });

  return response.data;
};
