import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid2,
  Switch,
} from "@mui/material";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import AcceptanceTerm from "../../../@types/services/AcceptanceTerm";
import Term from "../../../@types/services/Term";
import TermDocument from "../../../@types/services/TermDocument";
import useAuth from "../../../hooks/useAuth";
import { createAcceptanceTerm } from "../../../services/acceptanceTerms";
import { fetchTerms } from "../../../services/terms";

type AcceptanceTermFormProps = {
  term: Term;
  termDocument: TermDocument;
  acceptanceTerm?: AcceptanceTerm;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setTerms: Dispatch<SetStateAction<Term[] | undefined>>;
};

type AcceptanceTermForm = {
  accepted: boolean;
};

const AcceptanceTermForm: FC<AcceptanceTermFormProps> = ({
  term,
  termDocument,
  acceptanceTerm,
  setLoading,
  setTerms,
}) => {
  const { user } = useAuth();
  const [internalLoading, setInternalLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<AcceptanceTermForm>();

  const handleFetchTerms = () => {
    setLoading(true);
    fetchTerms()
      .then((response) => {
        setTerms(response?.results || undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit: SubmitHandler<AcceptanceTermForm> = useCallback((data) => {
    setInternalLoading(true);
    const formData = { ...data, term_document_id: termDocument.id };

    createAcceptanceTerm(formData)
      .then(() => {
        handleFetchTerms();
        enqueueSnackbar(i18next.t("acceptanceTermForm.success"), {
          variant: "success",
        });
        setOpenConfirm(false);
      })
      .finally(() => {
        setInternalLoading(false);
      });
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid2 container spacing={2}>
        <Grid2
          size={{ xs: 12 }}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Controller
            name="accepted"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={acceptanceTerm?.accepted || field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        setOpenConfirm(true);
                      }}
                      disabled={
                        acceptanceTerm?.accepted || field.value || false
                      }
                    />
                  }
                  label={i18next.t("acceptanceTermForm.acceptTerm")}
                />
              </>
            )}
          />
        </Grid2>
      </Grid2>

      <Dialog
        open={openConfirm}
        onClose={() => {
          setValue("accepted", false);
          setOpenConfirm(false);
        }}
      >
        <DialogTitle>
          {i18next.t("terms.dialog.title")} {term.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!!user?.admin_of_vendor ? (
              i18next.t("terms.dialog.message", { term: term.name })
            ) : (
              <Alert severity="warning" sx={{ marginTop: 1 }}>
                {i18next.t("terms.alert.userNoIsAdminOfVendor")}
              </Alert>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="button"
            color="error"
            loading={internalLoading}
            loadingPosition="center"
            onClick={() => {
              setValue("accepted", false);
              setOpenConfirm(false);
            }}
          >
            {i18next.t("terms.dialog.cancel")}
          </LoadingButton>
          <LoadingButton
            type="submit"
            color="success"
            variant="contained"
            loading={internalLoading}
            loadingPosition="center"
            onClick={handleSubmit(onSubmit)} // FIXME: This is a workaround because the button is not submitting the form
            disabled={!user?.admin_of_vendor}
          >
            {i18next.t("terms.dialog.confirm")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default AcceptanceTermForm;
