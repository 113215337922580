/* eslint-disable no-self-assign */
/* eslint-disable react/jsx-props-no-spreading */
import { mdiDownload } from "@mdi/js";
import Icon from "@mdi/react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import i18next from "i18next";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import {
  DocumentProps,
  addDocument,
  fetchDocuments,
} from "../../../services/serviceQuotations";

const label = { inputProps: { "aria-label": "Switch demo" } };

interface ListAttachmentServiceQuotationProps {
  serviceQuotationId: number;
}

const ListAttachmentServiceQuotation: FC<
  ListAttachmentServiceQuotationProps
> = ({ serviceQuotationId }) => {
  const [attach, setAttach] = useState<File | undefined>();
  const [documents, setDocuments] = useState<DocumentProps[]>([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (): Promise<void> => {
    setLoading(() => true);
    if (!attach) {
      return;
    }

    const formData = new FormData();
    formData.append("attachment", attach);

    addDocument(serviceQuotationId, formData)
      .then(() => {
        enqueueSnackbar("Arquivo enviado com sucesso!");
        fetchDocuments(serviceQuotationId).then((resource) => {
          setDocuments(resource);
        });
        setAttach(undefined);
      })
      .catch(() => {
        enqueueSnackbar("Não foi possível enviar o arquivo!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownload = async (
    url: string,
    filename: string,
  ): Promise<void> => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    fetchDocuments(serviceQuotationId).then((resource) => {
      setDocuments(resource);
    });
  }, [enqueueSnackbar, serviceQuotationId]);

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ width: 500 }}>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            gap: "5px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <input
            type="file"
            onChange={(e) => setAttach(() => e.target.files?.[0])}
            style={{
              border: "1px solid black",
              borderRadius: "2px",
              padding: "5px",
            }}
          />
          <LoadingButton
            variant="contained"
            loading={loading}
            loadingPosition="center"
            onClick={() => onSubmit()}
            disabled={!attach}
          >
            {i18next.t("serviceQuotation.button.sendFile")}
          </LoadingButton>
        </div>
      </Stack>
      <Card sx={{ margin: 2, minWidth: 275 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: 170 }}>
                {i18next.t("user")}
              </TableCell>
              <TableCell align="center" style={{ minWidth: 170 }}>
                {i18next.t("filename")}
              </TableCell>
              <TableCell align="center" style={{ minWidth: 170 }}>
                #
              </TableCell>
              {/* <TableCell align="center" style={{ minWidth: 170 }}>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="textSecondary"
                  gutterBottom
                >
                  {i18next.t("serviceQuotation.visibleForRequester")}
                </Typography>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.length > 0 &&
              documents.map((document) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={document.url}
                >
                  <TableCell align="center">
                    <Typography sx={{ fontSize: 14 }} color="textSecondary">
                      {`${document.mail_user} - ${i18next.t("dateTime", {
                        val: Date.parse(String(document.created_at)),
                        interpolation: { escapeValue: false },
                        formatParams: {
                          val: {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            // hour12: false,
                          },
                        },
                      })}`}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: 14 }} color="textSecondary">
                      {document.filename}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleDownload(document.url, document.filename)
                      }
                    >
                      Download <Icon path={mdiDownload} size={0.8} />
                    </Button>
                  </TableCell>
                  {/* <TableCell align="center">
                    <Switch {...label} />
                  </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Card>

      {/* <BasicBar title="Vendor Attachments" />

      <Card sx={{ margin: 2, minWidth: 275 }}>
        {serviceNegotiations && (
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <MainContainer>
              <ConversationList
                style={{
                  height: "400px",
                }}
              >
                {serviceNegotiations &&
                  serviceNegotiations.map(
                    (serviceNegotiation: ServiceNegotiation) => (
                      <Conversation
                        key={serviceNegotiation.id}
                        name={serviceNegotiation.vendor.fullvendorname} // Substitua pelo campo desejado
                        lastSenderName={serviceNegotiation.chat.resource_type} // Substitua pelo campo desejado
                        info={String(serviceNegotiation.created_at)} // Substitua pelo campo desejado
                        onClick={() =>
                          handleConversationClick(serviceNegotiation)
                        }
                      />
                    ),
                  )}
              </ConversationList>
              {selectedServiceNegotiation && (
                <ChatContainer
                  style={{
                    height: "400px",
                  }}
                >
                  <ConversationHeader>
                    <ConversationHeader.Content
                      userName={`${selectedServiceNegotiation.vendor.fullvendorname}`}
                    />
                    <ConversationHeader.Actions>
                      <InfoButton />
                    </ConversationHeader.Actions>
                  </ConversationHeader>
                  <MessageList>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" style={{ minWidth: 170 }}>
                            User
                          </TableCell>
                          <TableCell align="center" style={{ minWidth: 170 }}>
                            Filename
                          </TableCell>
                          <TableCell align="center" style={{ minWidth: 170 }}>
                            #
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {vendorDocuments.length > 0 &&
                          vendorDocuments.map((document) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={document.url}
                            >
                              <TableCell align="center">
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  color="textSecondary"
                                >
                                  {`${document.mail_user} - ${i18next.t(
                                    "dateTime",
                                    {
                                      val: Date.parse(
                                        String(document.created_at),
                                      ),
                                      interpolation: { escapeValue: false },
                                      formatParams: {
                                        val: {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric",
                                          hour: "numeric",
                                          minute: "numeric",
                                          second: "numeric",
                                          // hour12: false,
                                        },
                                      },
                                    },
                                  )}`}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  color="textSecondary"
                                >
                                  {document.filename}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    handleDownload(
                                      document.url,
                                      document.filename,
                                    )
                                  }
                                >
                                  Download{" "}
                                  <Icon path={mdiDownload} size={0.8} />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </MessageList>
                </ChatContainer>
                // <Chat
                //   chat={selectedServiceNegotiation.chat}
                //   vendor={selectedServiceNegotiation.vendor}
                // />
              )}
            </MainContainer>
          </Box>
        )}
      </Card> */}
    </>
  );
};

export default ListAttachmentServiceQuotation;
