import {
  mdiAccountCircle,
  mdiBrightness4,
  mdiBrightness7,
  mdiInformationOutline,
  mdiLogout,
  mdiMenu,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import i18next from "i18next";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { Link as LinkRoute } from "react-router-dom";
import devTag from "../../../../images/dev-tag.png";
import equinorLogoRedHorizontal from "../../../../images/equinor-logo-red-horizontal.svg";
import homologTag from "../../../../images/homolog-tag.png";
import { InfoModalType } from "../../../@types/services";
import useAuth from "../../../hooks/useAuth";
import useInfoModal from "../../../hooks/useInfoModal";
import useThemeMode from "../../../hooks/useThemeMode";
import { EnvTag, Logo } from "./styles";

const InfoModal: FC<{ infoModal: InfoModalType }> = ({ infoModal }) => {
  const { fetchInfoModal } = useInfoModal();
  const [open, setOpen] = useState(infoModal.show_modal || false);
  const theme = useTheme();

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => {
    fetchInfoModal();
    setOpen(false);
  }, []);

  return (
    <>
      <Button
        color="warning"
        variant="contained"
        size="small"
        sx={{
          width: "100%",
          gridArea: "info_modal",
          textTransform: "capitalize",
        }}
        onClick={handleOpen}
      >
        {infoModal.button}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-info-modal"
        aria-describedby="dialog-info-modal-description"
      >
        <DialogContent
          id="dialog-info-modal-description"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Icon
            size={4}
            path={mdiInformationOutline}
            color={
              theme.palette.mode === "dark"
                ? theme.palette.info.dark
                : theme.palette.info.light
            }
          />
          <Typography variant="h6" component="div">
            {infoModal.title}
          </Typography>
          <DialogContentText
            dangerouslySetInnerHTML={{ __html: infoModal.content }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{i18next.t("ok")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TopMenu: FC<{ setLeftMenuOpen: Dispatch<SetStateAction<boolean>> }> = ({
  setLeftMenuOpen,
}): ReactElement => {
  const { user, signOut } = useAuth();
  const { infoModal } = useInfoModal();
  const { toggleThemeMode } = useThemeMode();
  const theme = useTheme();
  const [envTag] = useState(() => {
    if (window.environment === "development") {
      return devTag;
    }
    if (window.environment === "homolog") {
      return homologTag;
    }
    return null;
  });
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = false;

  const handleLeftMenu = useCallback(() => {
    setLeftMenuOpen((currentValue) => !currentValue);
  }, []);

  const handleLogout = useCallback(() => {
    return signOut();
  }, [signOut]);

  return (
    <AppBar
      position="sticky"
      color="white"
      sx={{ zIndex: (currentTheme) => currentTheme.zIndex.drawer + 1 }}
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          justifyContent: "center",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: 1,
          gridTemplateRows: "auto",
          gridTemplateAreas: `"logo . infos header"`,
        }}
      >
        <Toolbar
          sx={{
            height: (currentTheme) => currentTheme.mixins.toolbar.minHeight,
            gridArea: "logo",
          }}
        >
          {isMobile ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleLeftMenu}
              sx={{ mr: 2 }}
            >
              <Icon size={0.75} path={mdiMenu} />
            </IconButton>
          ) : (
            <>
              {envTag && <EnvTag src={envTag} />}
              <Logo src={equinorLogoRedHorizontal} />
            </>
          )}
        </Toolbar>
        <Box
          sx={{
            gridArea: "infos",
            alignItems: "center",
            justifyContent: "center",
            display: "grid",
            gridTemplateColumns: "auto",
            gap: 2,
            gridTemplateRows: "auto",
            gridTemplateAreas: `"info_modal release_notes"`,
          }}
        >
          {infoModal && infoModal.info_modal_enabled && (
            <InfoModal infoModal={infoModal} />
          )}
        </Box>
        <Box
          sx={{
            gridArea: "header",
            alignItems: "center",
            justifyContent: "center",
            display: "grid",
            gridTemplateColumns: "auto",
            gap: 2,
            gridTemplateRows: "auto",
            gridTemplateAreas: `"mode account"`,
            marginRight: 1,
          }}
        >
          <IconButton
            onClick={toggleThemeMode}
            color="energyRed"
            sx={{ ml: 1, gridArea: "mode" }}
          >
            {theme.palette.mode === "dark" ? (
              <Icon size={0.75} path={mdiBrightness7} />
            ) : (
              <Icon size={0.75} path={mdiBrightness4} />
            )}
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography color="energyRed">
              {user?.full_name_with_login}
            </Typography>
            <Box
              component="main"
              sx={{
                gridArea: "actions",
                flexGrow: 1,
                justifyContent: "center",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: 1,
                gridTemplateRows: "auto",
                gridTemplateAreas: !!user?.vendor
                  ? `"my_account sign_out my_vendor"`
                  : `"my_account sign_out"`,
              }}
            >
              {user && (
                <>
                  <Button
                    color="energyRed"
                    variant="text"
                    size="small"
                    sx={{
                      width: "100%",
                      gridArea: "my_account",
                      textTransform: "capitalize",
                    }}
                    component={LinkRoute}
                    to="/users/edit"
                    startIcon={<Icon size={0.75} path={mdiAccountCircle} />}
                  >
                    {i18next.t("topMenu.myAccount")}
                  </Button>
                  <Button
                    color="energyRed"
                    variant="text"
                    size="small"
                    sx={{
                      width: "100%",
                      gridArea: "sign_out",
                      textTransform: "capitalize",
                    }}
                    onClick={handleLogout}
                    startIcon={<Icon size={0.75} path={mdiLogout} />}
                  >
                    {i18next.t("topMenu.signOut")}
                  </Button>
                  {!!user?.vendor && (
                    <Button
                      color="energyRed"
                      variant="text"
                      size="small"
                      sx={{
                        width: "100%",
                        gridArea: "my_vendor",
                        textTransform: "capitalize",
                      }}
                      component={LinkRoute}
                      to="/vsupplierregistration/vendor"
                    >
                      {i18next.t("topMenu.myVendor")}
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
};

export default TopMenu;
