/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import i18next from "i18next";
import { useSnackbar } from "notistack";
import {
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import Vendor from "../../../@types/services/Vendor";
import { inviteVendors } from "../../../services/serviceQuotations";
import { VendorsFilter, fetchVendors } from "../../../services/vendors";

interface InviteSupplierProps {
  serviceQuotationId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const InviteSuppliers: FC<InviteSupplierProps> = ({
  serviceQuotationId,
  open,
  setOpen,
}) => {
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [selectedVendors, setSelectedVendors] = useState<Vendor[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openOptions, setOpenOptions] = useState(false);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const loading = openOptions && vendors.length === 0;
  const rowsPerPage = 25;

  const [page, setPage] = useState(1);

  const handleFetchVendors = useCallback(
    async (pageNumber: number, filters?: VendorsFilter) => {
      if (filters && filters.name.length >= 3) {
        const response = await fetchVendors({
          page: pageNumber,
          itemsPerPage: rowsPerPage,
          filters,
        });
        if (response.results) {
          setVendors([]);
          setVendors(response.results);
          setPage(page + 1);
        } else {
          enqueueSnackbar(i18next.t("inviteVendors.notFoundVendors"));
        }
      }
    },
    [page],
  );

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    handleFetchVendors(page);
  }, [loading, page, handleFetchVendors]);

  const handleOptionSelected = async (selectedOptions: Vendor[]) => {
    setVendors([]);
    setPage(1);
    setSelectedVendors((prevSelectedVendors) => {
      const novosElementos = selectedOptions.filter(
        (selectedOption) =>
          !prevSelectedVendors.some(
            (existingOption) => existingOption.id === selectedOption.id,
          ),
      );

      return [...prevSelectedVendors, ...novosElementos];
    });
  };
  const handleOptionRemoved = (removedVendor: Vendor) => {
    setSelectedVendors((prevSelectedVendors) =>
      prevSelectedVendors.filter(
        (existingVendor) => existingVendor.id !== removedVendor.id,
      ),
    );
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedVendors.length > 0) {
      inviteVendors(serviceQuotationId, selectedVendors)
        .then(() => {
          enqueueSnackbar("Vendors convidados com sucesso!");
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              enqueueSnackbar(i18next.t("inviteVendors.notFoundVendors"));
            } else if (error.response.data.status === "warning") {
              enqueueSnackbar(error.response.data.message, {
                variant: "error",
                autoHideDuration: 10000,
              });
            } else {
              enqueueSnackbar(i18next.t("inviteVendors.unableToInviteVendors"));
            }
          } else {
            enqueueSnackbar(i18next.t("inviteVendors.unableToInviteVendors"));
          }
        })
        .finally(() => {
          setLoadingInvite(() => false);
          // eslint-disable-next-line no-self-assign
          window.location.href = window.location.href;
        });
    } else {
      enqueueSnackbar(i18next.t("inviteVendors.notSubmitEmptyList"));
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
        sx: { width: "100%" },
      }}
    >
      <DialogTitle>{i18next.t("invitation")}</DialogTitle>
      <DialogContent style={{ paddingTop: 20, maxHeight: "200px" }}>
        <Stack spacing={1} sx={{ width: 500 }}>
          <Autocomplete
            multiple
            disableClearable={true}
            id="asynchronous-demo"
            sx={{ width: 500 }}
            open={openOptions}
            onOpen={() => {
              setOpenOptions(true);
            }}
            onClose={() => {
              setOpenOptions(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) =>
              `${option.sap_code} - ${option.fullvendorname}`
            }
            options={vendors}
            noOptionsText={"Digite pelo menos 3 caracteres para buscar..."}
            onInputChange={(event, value) => {
              handleFetchVendors(1, {
                name: value,
                email: "",
                tax_number: "",
                sap_code: "",
              });
            }}
            onChange={(event, value, reason, details) => {
              if (reason === "selectOption") {
                handleOptionSelected(value);
              } else if (reason === "removeOption") {
                if (details?.option) {
                  handleOptionRemoved(details?.option);
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                key={params.inputProps.id}
                label={i18next.t("vendors")}
                placeholder={i18next.t(
                  "serviceQuotation.placeholder.enterSupplier",
                )}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading &&
                        params?.inputProps.value &&
                        (params.inputProps.value as string).length >= 3 ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  },
                }}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{i18next.t("cancel")}</Button>
        {/* <Button type="submit">Send</Button> */}
        <LoadingButton
          variant="contained"
          loading={loadingInvite}
          loadingPosition="center"
          type="submit"
        >
          {i18next.t("invite")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default InviteSuppliers;
