/* eslint-disable react/jsx-pascal-case */
import {
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { FC, useEffect, useState } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

interface SelectFieldProps {
  label: string;
  name: FieldPath<FieldValues>;
  // onChange?: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  axiosFunction: () => Promise<AxiosResponse>;
  control: Control<FieldValues>;
}

const FilterSelectField: FC<SelectFieldProps> = ({
  label,
  name,
  axiosFunction,
  control,
}) => {
  const [options, setOptions] = useState<string[]>([]);

  const handleFetchOptions = () => {
    axiosFunction().then(({ results }: any) => {
      setOptions(results.filter(Boolean));
    });
  };

  useEffect(() => {
    handleFetchOptions();
  }, []);

  return (
    <Grid2 size={{ xs: 12, md: 6 }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Select
              {...field}
              id={`${name}_id`}
              label={`${name}_label`}
              name={name}
              value={field.value || ""}
              defaultValue=""
            >
              <MenuItem key="" value="" />
              {options &&
                options.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      />
    </Grid2>
  );
};

export default FilterSelectField;
