import Term from "../@types/services/Term";
import api from "./api";
import { createTermDocument } from "./termDocuments";

const API_BASE_URL = "/vsupplierregistration/api/v2/terms";

export type NewTermForm = {
  name_pt_br: string;
  name_en: string;
  mandatory: boolean;
  active: boolean;
  attachment: File;
};

export type TermInputForm = {
  name_pt_br: string;
  name_en: string;
  mandatory: boolean;
  active: boolean;
};

interface TermsResponse {
  total_items: number;
  results: Term[] | null;
}

export const fetchTerms = async (): Promise<TermsResponse> => {
  const response = await api.get<TermsResponse>("", {
    baseURL: API_BASE_URL,
  });

  return response.data;
};

export const fetchTerm = async (id: string): Promise<Term> => {
  const response = await api.get<Term>(`/${id}`, {
    baseURL: API_BASE_URL,
  });
  return response.data;
};

export const createTerm = async (data: NewTermForm): Promise<Term> => {
  const response = await api.post<Term>("", data, {
    baseURL: API_BASE_URL,
  });

  await createTermDocument(
    { ...data, term_id: response.data.id },
    data.attachment,
  );

  return response.data;
};

export const updateTerm = async (
  termId: string,
  data: TermInputForm,
): Promise<Term> => {
  const response = await api.put<Term>(`/${termId}`, data, {
    baseURL: API_BASE_URL,
  });

  return response.data;
};
