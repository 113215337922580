import {
  mdiAccountMultiple,
  mdiCart,
  mdiChartPie,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiClipboardAccount,
  mdiClipboardCheck,
  mdiCloseCircle,
  mdiContentPaste,
  mdiEmail,
  mdiFormatListBulleted,
  mdiHelpCircle,
  mdiLightbulbVariant,
  mdiListBoxOutline,
  mdiPencil,
  mdiReceiptText,
  mdiShieldCrown,
  mdiSwapHorizontal,
  mdiTruck,
  mdiViewList,
  mdiViewQuilt,
  mdiWrench,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  Box,
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Dispatch,
  FC,
  Fragment,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import LeftMenuInterface from "../../../@types/components/organisms/LeftMenu";
import { Drawer, DrawerBottom } from "./styles";

interface MenuListItemButtonProps extends ListItemButtonProps {
  menu: LeftMenuInterface;
}

const MenuListItemButton: FC<MenuListItemButtonProps> = (
  props,
): ReactElement => {
  const { menu, children } = props;

  if (!menu.submenu) {
    if (menu.is_react) {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ListItemButton component={Link} to={menu.url || ""} {...props}>
          {children}
        </ListItemButton>
      );
    }
    if (menu.url) {
      return (
        <ListItemButton
          component="a"
          href={!menu.is_react ? menu.url : undefined}
          {...props} // eslint-disable-line react/jsx-props-no-spreading
        >
          {children}
        </ListItemButton>
      );
    }
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ListItemButton {...props}>{children}</ListItemButton>;
};

const LeftMenu: FC<{
  options: LeftMenuInterface[];
  leftMenuOpen: boolean;
  setLeftMenuOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ options, leftMenuOpen, setLeftMenuOpen }): ReactElement => {
  const theme = useTheme();
  const [openSubmenu, setOpenSubmenu] = useState<{
    [key: string | number]: boolean;
  }>({});
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [icons] = useState({
    assignment_ind: mdiClipboardAccount,
    assignment_turned_in: mdiClipboardCheck,
    cancel: mdiCloseCircle,
    content_paste: mdiContentPaste,
    create: mdiPencil,
    emoji_objects: mdiLightbulbVariant,
    format_list_bulleted: mdiFormatListBulleted,
    group: mdiAccountMultiple,
    help: mdiHelpCircle,
    list: mdiViewList,
    local_shipping: mdiTruck,
    mail: mdiEmail,
    pie_chart: mdiChartPie,
    receipt: mdiReceiptText,
    shopping_cart: mdiCart,
    sync_alt: mdiSwapHorizontal,
    view_quilt: mdiViewQuilt,

    // MDI icon
    "mdi-shield-crown": mdiShieldCrown,
    "mdi-list-box-outline": mdiListBoxOutline,
    "mdi-wrench": mdiWrench,
  });

  const handleLeftMenu = useCallback(() => {
    setLeftMenuOpen((currentValue) => !currentValue);
  }, [setLeftMenuOpen]);

  const handleClick = useCallback(
    (e: string | number) => {
      setLeftMenuOpen(() => true); // Force menu expanded to show nested options
      setOpenSubmenu((currentValue) => ({ [e]: !currentValue[e] }));
    },
    [setLeftMenuOpen],
  );

  const getChevronIcon = useCallback(
    (status: boolean) => {
      if (status) {
        return theme.direction === "rtl" ? mdiChevronRight : mdiChevronLeft;
      }

      return theme.direction === "rtl" ? mdiChevronLeft : mdiChevronRight;
    },
    [theme.direction],
  );

  useEffect(() => {
    if (isMobile) {
      setLeftMenuOpen(() => false);
    }
  }, [isMobile, setLeftMenuOpen]);

  useEffect(() => {
    if (!leftMenuOpen) {
      setOpenSubmenu(() => ({})); // Force all submenu close
    }
  }, [leftMenuOpen]);

  return (
    <Drawer
      // variant={isMobile ? "temporary" : "permanent"}
      variant="permanent"
      open={leftMenuOpen}
      sx={{
        flexShrink: 0,
      }}
    >
      <Box sx={{ marginTop: `${theme.mixins.toolbar.minHeight}px` }}>
        <List component="nav" aria-labelledby="main-nav">
          {options.map((menu: LeftMenuInterface) => (
            <Fragment key={menu.name}>
              <MenuListItemButton
                menu={menu}
                key={menu.name}
                onClick={() => handleClick(menu.name)}
                title={menu.text}
              >
                <ListItemIcon sx={{ minWidth: "1.5rem" }}>
                  {menu.icon && <Icon path={icons[menu.icon]} size={0.75} />}
                </ListItemIcon>
                {leftMenuOpen && (
                  <ListItemText
                    primary={menu.text}
                    sx={{ whiteSpace: "normal" }}
                    slotProps={{
                      primary: { sx: { fontSize: 14 } },
                    }}
                  />
                )}
                {menu.submenu && (
                  <Icon
                    path={
                      openSubmenu[menu.name] ? mdiChevronUp : mdiChevronDown
                    }
                    size={0.75}
                  />
                )}
              </MenuListItemButton>
              {menu.submenu &&
                menu.submenu.map((submenu) => (
                  <Collapse
                    key={submenu.name}
                    in={openSubmenu[menu.name]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {submenu.is_react ? (
                        <ListItemButton
                          key={submenu.name}
                          sx={{ pl: 4 }}
                          title={submenu.text}
                          component={Link}
                          to={submenu.url}
                        >
                          <ListItemText
                            primary={submenu.text}
                            sx={{ whiteSpace: "normal" }}
                            slotProps={{
                              primary: { sx: { fontSize: 14 } },
                            }}
                          />
                        </ListItemButton>
                      ) : (
                        <ListItemButton
                          key={submenu.name}
                          sx={{ pl: 4 }}
                          title={submenu.text}
                          component="a"
                          href={submenu.url}
                        >
                          {/* <ListItemIcon sx={{ minWidth: '1.5rem' }}>
                        {submenu.icon && <Icon path={icons[submenu.icon]} size={0.75} />}
                      </ListItemIcon> */}
                          <ListItemText
                            primary={submenu.text}
                            sx={{ whiteSpace: "normal" }}
                            slotProps={{
                              primary: { sx: { fontSize: 14 } },
                            }}
                          />
                        </ListItemButton>
                      )}
                    </List>
                  </Collapse>
                ))}
            </Fragment>
          ))}
        </List>
      </Box>
      <DrawerBottom>
        <Button
          onClick={handleLeftMenu}
          color="energyRed"
          variant="contained"
          size="large"
          sx={{ width: "100%", "&:hover": { backgroundColor: "#de002e" } }}
        >
          <Icon path={getChevronIcon(leftMenuOpen)} size={1} />
        </Button>
      </DrawerBottom>
    </Drawer>
  );
};

export default LeftMenu;
