import { Box, Paper } from "@mui/material";
import i18next from "i18next";
import { ReactNode } from "react";
import PageTitle from "../atoms/PageTitle";

interface TemplateTermsProps {
  children: ReactNode;
}

const TemplateTerms = ({ children }: TemplateTermsProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: "16px",
        width: "100%",
      }}
    >
      <PageTitle title={i18next.t("terms.title")} />

      <Paper
        sx={{
          marginTop: 2,
          display: "flex",
          maxWidth: "100%",
          height: "100%",
          flexDirection: "column",
          padding: "16px",
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};

export default TemplateTerms;
