import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { FC } from "react";
import Audits from "../../../@types/services/Audits";
import AuditsPL from "../../../@types/services/AuditsPL";
import Proposal from "../../../@types/services/Proposal";
import ProposalLine from "../../../@types/services/ProposalLine";

type ProposalAuditProps = {
  proposal: Proposal;
};

const ProposalAudit: FC<ProposalAuditProps> = ({ proposal }) => {
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<Icon path={mdiChevronDown} size={0.8} />}
          aria-controls="panel1-content"
          id="panel1-header"
          disabled={!proposal}
        >
          <Typography component="span">Proposal</Typography>
        </AccordionSummary>

        <Divider />

        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell> Date </TableCell>
                  <TableCell align="right">Field</TableCell>
                  <TableCell align="right">old value</TableCell>
                  <TableCell align="right">new value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {proposal.audits &&
                  proposal.audits
                    .sort((a, b) => {
                      return (
                        new Date(a.created_at).getTime() -
                        new Date(b.created_at).getTime()
                      );
                    })
                    .map((audit: Audits) => (
                      <TableRow key={audit.id}>
                        <TableCell>
                          {audit.created_at &&
                            i18next.t("dateTime", {
                              val: Date.parse(audit.created_at),
                              interpolation: {
                                escapeValue: false,
                              },
                              formatParams: {
                                val: {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                  // hour12: false,
                                },
                              },
                            })}
                        </TableCell>
                        <TableCell align="right">
                          Delivery Date
                          <br />
                          Currency
                        </TableCell>
                        <TableCell align="right">
                          {Array.isArray(audit.audited_changes.delivery_date)
                            ? audit.audited_changes.delivery_date[0]
                            : "-"}
                          <br />
                          {Array.isArray(audit.audited_changes.currency_id)
                            ? audit.audited_changes.currency_id[0]
                            : "-"}
                        </TableCell>
                        <TableCell align="right">
                          {audit.audited_changes.delivery_date
                            ? Array.isArray(audit.audited_changes.delivery_date)
                              ? audit.audited_changes.delivery_date[1]
                              : audit.audited_changes.delivery_date
                            : "-"}
                          <br />
                          {audit.audited_changes.currency_id
                            ? Array.isArray(audit.audited_changes.currency_id)
                              ? audit.audited_changes.currency_id[1]
                              : audit.audited_changes.currency_id
                            : "-"}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<Icon path={mdiChevronDown} size={0.8} />}
          aria-controls="panel1-content"
          id="panel1-header"
          disabled={!proposal}
        >
          <Typography component="span">Lines</Typography>
        </AccordionSummary>

        <Divider />
        <AccordionDetails>
          {proposal.proposal_lines.map((proposalLine: ProposalLine) => (
            <Accordion key={proposalLine.id}>
              <AccordionSummary
                expandIcon={<Icon path={mdiChevronDown} size={0.8} />}
                aria-controls="panel1-content"
                id="panel1-header"
                disabled={!proposal}
              >
                <Typography component="span">
                  {proposalLine.short_text}
                </Typography>
              </AccordionSummary>

              <Divider />
              <AccordionDetails>
                {proposalLine && (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell align="right">Field</TableCell>
                          <TableCell align="right">old value</TableCell>
                          <TableCell align="right">new value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {proposalLine.audits &&
                          proposalLine.audits.map((audit: AuditsPL) => (
                            <TableRow key={audit.id}>
                              <TableCell>
                                {audit.created_at &&
                                  i18next.t("dateTime", {
                                    val: Date.parse(audit.created_at),
                                    interpolation: {
                                      escapeValue: false,
                                    },
                                    formatParams: {
                                      val: {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        second: "numeric",
                                        // hour12: false,
                                      },
                                    },
                                  })}
                              </TableCell>
                              <TableCell align="right">
                                GrossPrice
                                <br />
                                Quantity
                              </TableCell>
                              <TableCell align="right">
                                {Array.isArray(
                                  audit.audited_changes.gross_price,
                                )
                                  ? audit.audited_changes.gross_price[0]
                                  : "-"}
                                <br />
                                {Array.isArray(audit.audited_changes.quantity)
                                  ? audit.audited_changes.quantity[0]
                                  : "-"}
                              </TableCell>
                              <TableCell align="right">
                                {audit.audited_changes.gross_price
                                  ? Array.isArray(
                                      audit.audited_changes.gross_price,
                                    )
                                    ? audit.audited_changes.gross_price[1]
                                    : audit.audited_changes.gross_price
                                  : "-"}
                                <br />
                                {audit.audited_changes.quantity
                                  ? Array.isArray(
                                      audit.audited_changes.quantity,
                                    )
                                    ? audit.audited_changes.quantity[1]
                                    : audit.audited_changes.quantity
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ProposalAudit;
