import { useContext } from "react";
import InfoModalContextData from "../@types/contexts/InfoModal/InfoModalContext";
import InfoModalContext from "../contexts/InfoModal/InfoModalContext";

const useInfoModal = (): InfoModalContextData => {
  const context = useContext(InfoModalContext);

  if (!context) {
    throw new Error("useInfoModal must be used within an InfoModalProvider");
  }

  return context;
};

export default useInfoModal;
