import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Term from "../../@types/services/Term";
import TermDocument from "../../@types/services/TermDocument";
import LoadingCentral from "../../components/molecules/LoadingCentral";
import TemplateTerms from "../../components/templates/TemplateTerms";
import { fetchTermDocument } from "../../services/termDocuments";
import { fetchTerms } from "../../services/terms";

interface ItemTermProps {
  term: Term;
}

const ItemTerm: FC<ItemTermProps> = ({ term }) => {
  const [termDocument, setTermDocument] = useState<TermDocument>();

  const handleFetchTermDocument = async () => {
    fetchTermDocument(term.active_term_document.id).then((data) =>
      setTermDocument(data),
    );
  };

  useEffect(() => {
    handleFetchTermDocument();
  }, []);

  if (!termDocument) {
    return <LoadingCentral />;
  }

  return (
    <Accordion key={term.id}>
      <AccordionSummary
        expandIcon={<Icon path={mdiChevronDown} size={1} />}
        aria-controls="terms-content"
        id="terms-header"
      >
        <Typography component="span" sx={{ width: "45%", flexShrink: 0 }}>
          {term.name}
        </Typography>

        <Typography component="span" sx={{ color: "text.secondary" }}>
          {i18next.t("terms.lastUpdate")}
          {i18next.t("dateTime", {
            val: Date.parse(String(termDocument.created_at)),
            interpolation: { escapeValue: false },
            formatParams: {
              val: {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                // hour12: false,
              },
            },
          })}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              margin: 1,
              padding: 1,
              width: "100%",
            }}
          >
            <ButtonGroup
              size="small"
              aria-label="Small button group"
              sx={{ "& > *": { mr: 1 } }}
            >
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to={`/vsupplierregistration/terms/${term.id}/edit`}
              >
                {i18next.t("terms.editTerm")}
              </Button>
            </ButtonGroup>
          </Box>
          <Divider />
        </>

        {termDocument && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <iframe
              src={termDocument.attachment.url_inline}
              width="100%"
              height="800px"
              style={{ marginTop: "16px" }}
              title={termDocument.attachment.filename}
            />
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const Terms: FC = () => {
  const [terms, setTerms] = useState<Term[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleFetchTerms = () => {
    setLoading(true);
    fetchTerms()
      .then((response) => {
        setTerms(response?.results || undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleFetchTerms();
  }, []);

  return (
    <TemplateTerms>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          margin: 1,
          padding: 1,
          width: "100%",
        }}
      >
        <ButtonGroup
          size="small"
          aria-label="Small button group"
          sx={{ "& > *": { mr: 1 } }}
        >
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to={`/vsupplierregistration/terms/new`}
          >
            {i18next.t("terms.newTerm")}
          </Button>
        </ButtonGroup>
      </Box>
      {loading && <LoadingCentral />}
      {terms && !loading && terms.map((term) => <ItemTerm term={term} />)}
    </TemplateTerms>
  );
};

export default Terms;
