import { LoadingButton } from "@mui/lab";
import { Checkbox, FormControlLabel, Grid2, TextField } from "@mui/material";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { FC, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Term from "../../../@types/services/Term";
import { fetchTerm, TermInputForm, updateTerm } from "../../../services/terms";

type TermFormProps = {
  term: Term;
  setTerm: (term: Term) => void;
};

const TermForm: FC<TermFormProps> = ({ term, setTerm }) => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TermInputForm>();

  const onSubmitTerm: SubmitHandler<TermInputForm> = (data) => {
    setLoading(true);
    console.log(data);
    updateTerm(term.id, data)
      .then(() => {
        enqueueSnackbar(i18next.t("termForm.updateTermSuccess"), {
          variant: "success",
        });
        fetchTerm(term.id).then((data) => {
          setTerm(data);
        });
      })
      .catch(() => {
        enqueueSnackbar(i18next.t("termForm.updateTermError"), {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitTerm)}>
      <Grid2 container spacing={1}>
        <Grid2 size={6}>
          <Controller
            name="name_pt_br"
            control={control}
            defaultValue={term.name_pt_br}
            render={({ field }) => (
              <TextField
                {...field}
                label={i18next.t("termForm.label.namePtBr")}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid2>
        <Grid2 size={6}>
          <Controller
            name="name_en"
            control={control}
            defaultValue={term.name_en}
            render={({ field }) => (
              <TextField
                {...field}
                label={i18next.t("termForm.label.nameEn")}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid2>
      </Grid2>
      <Grid2 container size={12}>
        <Grid2 size={6}>
          <Controller
            name="mandatory"
            control={control}
            defaultValue={term.mandatory}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={i18next.t("termForm.label.required")}
              />
            )}
          />
          <Controller
            name="active"
            control={control}
            defaultValue={term.active}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={i18next.t("termForm.label.active")}
              />
            )}
          />
        </Grid2>
        <Grid2
          size={6}
          sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
        >
          <Grid2 size={2}>
            <LoadingButton
              type="submit"
              style={{
                marginTop: "8px",
                justifyContent: "center",
              }}
              color="success"
              variant="contained"
              loading={loading}
              loadingPosition="center"
            >
              {i18next.t("termForm.button.updateTerm")}
            </LoadingButton>
          </Grid2>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default TermForm;
