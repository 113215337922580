import { mdiDownload } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Box,
  Button,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { FC, useEffect, useState } from "react";
import Proposal from "../../../@types/services/Proposal";
import { getArchivedProposalDocuments } from "../../../services/proposals";
import { DocumentProps } from "../../../services/serviceQuotations";
import LoadingCentral from "../LoadingCentral";

type ArchivedProposalsProps = {
  proposal: Proposal;
};

const ArchivedProposals: FC<ArchivedProposalsProps> = ({ proposal }) => {
  const [documents, setDocuments] = useState<DocumentProps[]>();

  const handleDownload = async (
    url: string,
    filename: string,
  ): Promise<void> => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    getArchivedProposalDocuments(proposal.id).then((resource) => {
      setDocuments(resource);
    });
  }, [proposal]);

  if (!documents) {
    return <LoadingCentral />;
  }

  return (
    <Card sx={{ width: "100%", minWidth: 275 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ minWidth: 170 }}>
              {i18next.t("archivedProposal.user")}
            </TableCell>
            <TableCell align="center" style={{ minWidth: 170 }}>
              {i18next.t("archivedProposal.date")}
            </TableCell>
            <TableCell align="center" style={{ minWidth: 170 }}>
              {i18next.t("archivedProposal.filename")}
            </TableCell>
            <TableCell align="center" style={{ minWidth: 170 }}>
              {i18next.t("archivedProposal.actions")}
            </TableCell>
            {/* <TableCell align="center" style={{ minWidth: 170 }}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="textSecondary"
                        gutterBottom
                      >
                        {i18next.t("serviceQuotation.visibleForRequester")}
                      </Typography>
                    </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.length > 0 &&
            documents.map((document) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={document.url}>
                <TableCell align="center">
                  <Typography sx={{ fontSize: 14 }} color="textSecondary">
                    {document.mail_user}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontSize: 14 }} color="textSecondary">
                    {`${i18next.t("dateTime", {
                      val: Date.parse(String(document.created_at)),
                      interpolation: { escapeValue: false },
                      formatParams: {
                        val: {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          // hour12: false,
                        },
                      },
                    })}`}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontSize: 14 }} color="textSecondary">
                    {document.filename}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    onClick={() =>
                      handleDownload(document.url, document.filename)
                    }
                  >
                    {i18next.t("archivedProposal.download")}{" "}
                    <Icon path={mdiDownload} size={0.8} />
                  </Button>
                </TableCell>
                {/* <TableCell align="center">
                          <Switch {...label} />
                        </TableCell> */}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {documents.length == null && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
            "& > *": {
              m: 1,
            },
          }}
        >
          <Chip
            label={<span>{i18next.t("proposal.noArchivedProposals")}</span>}
          />
        </Box>
      )}
    </Card>
  );
};

export default ArchivedProposals;
